import React from "react";

const TextComponent = ({
	label,
	type,
	name,
	id,
	placeholder,
	required,
	onChange,
	...props
}) => (
	<div className='flex flex-col gap-2 w-100'>
		<label htmlFor='password' {...props} className='text-lg'>
			{label}
		</label>
		<textarea
			type={type}
			name={name}
			onChange={onChange}
			{...props}
			id={id}
			required={required}
			className='p-3 border border-black rounded-md resize-none outline-none w-100 h-[106px]'
			placeholder={placeholder}
		/>
	</div>
);

export default TextComponent;
