import React, { useEffect, useState, useRef } from "react";
import style from "../allowedUsers/style.module.css";
import Box from "@mui/material/Box";
import Loader from "./../../components/atoms/Loader";
import { DataGrid } from "@mui/x-data-grid";
import { getNonSSNDetails } from "../../api/api";
import dayjs from "dayjs";

const columns = [
  { field: "id", headerName: "id", width: 50, hide: true },
  { field: "name", headerName: "Name", width: 200 },
  { field: "email", headerName: "Email", width: 200 },
  { field: "mobileNo", headerName: "Mobile No", width: 150 },
  // {
  //   field: "userId",
  //   headerName: "User Id",
  //   width: 200,
  // },
  {
    field: "createdAt",
    headerName: "Created At (EST)",
    width: 200,
    // renderCell: (params) => {
    //   const options = {
    //     hour12: false,
    //     year: "numeric",
    //     month: "2-digit",
    //     day: "2-digit",
    //     hour: "2-digit",
    //     minute: "2-digit",
    //     second: "2-digit",
    //   };
    //   const formattedDateTime = new Date(params.row.createdAt)
    //     .toLocaleString("en-US", options)
    //     .replace(
    //       /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/,
    //       "$1-$2-$3 $4:$5:$6"
    //     );

    //   return <p>{formattedDateTime}</p>;
    // },
    renderCell: (params) => (
      <p className="" title="yyyy-mm-dd:hh:mm:ss time format EST">
        {dayjs(params?.row?.createdAt)
          ?.tz("America/New_York")
          .format("YYYY-MM-DD:HH:mm:ss")}
      </p>
    ),
  },
];

export default function NonSSNStudent() {
  const [rowData, setRowData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getNonSSNDetails();
        console.log("data", data?.data?.internationalStudents);
        setLoading(false);
        // setRowData(data?.data?.acknowledgeSsnsUser);
        setRowData(data?.data?.internationalStudents);
      } catch (err) {
        console.log("err", err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.title}>International Student Details</div>
      </div>

      {loading ? (
        <div className={`flex flex-row justify-center align-center`}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={`flex flex-column gap-2 align-center h-auto`}>
            <Box sx={{ height: "83vh", width: "100%" }}>
              {rowData?.length > 0 ? (
                <DataGrid
                  rows={rowData}
                  columns={columns}
                  pagination
                  pageSize={10}
                />
              ) : null}
            </Box>
          </div>
        </>
      )}
    </div>
  );
}
