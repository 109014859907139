import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  getAdminAds,
  getCampaignInsightAPI,
  getParticipatedActiveUsersAPI,
} from "../../api/api";
import DownloadCSV from "../../shared/downloadCSV/DownloadCSV";
import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";

const ViewCampaignInsight = ({ open, handleClose, id }) => {
  const [activeInsight, setActiveInsight] = useState(0);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("All");
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [participatedUsers, setParticipatedUsers] = useState([]);
  const [hasFetchedParticipatedUsers, setHasFetchedParticipatedUsers] =
    useState(false); // New state for API fetch tracking

  const popupRef = useRef(null);

  const getData = async (id) => {
    setLoading(true);
    try {
      const response = await getCampaignInsightAPI(id);

      // console.log(response?.data);

      setData(response.data || []);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    } finally {
      setLoading(false);
    }
  };
  const getParticipatedActiveUsers = async (campaignId) => {
    try {
      const response = await getParticipatedActiveUsersAPI(campaignId); // Ensure you have this API implemented
      // console.log(response);
      setParticipatedUsers(response.data || []);
    } catch (error) {
      console.error("Error fetching participated users:", error);
      toast.error("Error fetching participated users");
    }
  };
  useEffect(() => {
    if (id) {
      //   console.log("bharat00000");
      open && getData(id);
    }
  }, [id, open]);

  useEffect(() => {
    // Function to handle clicks outside of the popup
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClose();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, handleClose]);
  useEffect(() => {
    if (filter) {
      //   console.log("bharat", filter, filteredData);
      setFilteredData(
        data.filter((row) =>
          filter === "All"
            ? true
            : row?.status?.toUpperCase() === filter?.toUpperCase()
        )
      );
    } else {
      setFilteredData(data);
    }
  }, [filter, data]);

  useEffect(() => {
    if (activeInsight === 1 && !hasFetchedParticipatedUsers) {
      getParticipatedActiveUsers(id);
      setHasFetchedParticipatedUsers(true); // Set to true after fetching
    }
  }, [activeInsight, id, hasFetchedParticipatedUsers]);

  const insightColumns = [
    { field: "clientId", headerName: "Client ID", width: 100, download: true },
    {
      field: "studentName",
      headerName: "Student Name",
      width: 200,
      download: true,
    },

    {
      field: "claimed",
      headerName: "Claimed",
      width: 200,
      renderCell: (params) => {
        return <p>{params?.row?.transferId ? "Claimed" : "Not Claimed"}</p>;
      },
      download: true,
    },
    {
      field: "savingAccountId",
      headerName: "Saving Account Id",
      width: 200,
      download: true,
    },
    {
      field: "cashbackAmount",
      headerName: "Cashback Amount",
      width: 200,
      download: true,
    },
    {
      field: "transferId",
      headerName: "Transfer Id",
      width: 200,
      download: true,
    },
    {
      field: "activationTimestamp",
      headerName: "Activation Timestamp",
      width: 200,
      download: true,
      valueGetter: (params) => {
        const date = dayjs(params.value).tz("America/Los_Angeles");
        return date.isValid() ? date.format("YYYY-MM-DD:HH:mm:ss") : ""; // Format as YYYY-MM-DD
      },
    },
  ];

  const participatedUserColumns = [
    { field: "id", headerName: "ID", width: 100, download: true },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      download: true,
    },
    {
      field: "activationTimestamp",
      headerName: "Activation Timestamp",
      width: 200,
      download: true,
      valueGetter: (params) => {
        const date = dayjs(params.value).tz("America/Los_Angeles");
        return date.isValid() ? date.format("YYYY-MM-DD") : ""; // Format as YYYY-MM-DD
      },
    },
  ];

  const pageSize = Math.min(
    10,
    Math.max(
      5,
      activeInsight !== 1 ? filteredData.length : participatedUsers?.length
    )
  );
  // const pageSize = 5;
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center w-full bg-gray-900 bg-opacity-50 transition-opacity ${
        open ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
      style={{ zIndex: 9999 }}
    >
      <div
        ref={popupRef}
        className="bg-white w-11/12 md:w-2/3 lg:w-2/3 xl:w-2/3 max-w-[880px] rounded-lg shadow-lg p-6 overflow-hidden max-h-screen relative"
      >
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl hover:border-1 transition-all duration-100 rounded-full px-2 box-border"
          aria-label="Close"
        >
          &times;
        </button>

        {/* <h2 className="text-xl font-bold mb-4 hover:scale-110 duration-300 w-fit">
            Campaign Insight
          </h2> */}
        <div className="flex flex-row gap-3 items-center pb-2">
          <div
            onClick={() => setActiveInsight(0)}
            className={`cursor-pointer h-[43px] flex justify-center items-center py-3 px-5 
      text-[#67748e] text-lg font-bold whitespace-nowrap
      transition duration-300 ease-in-out border-b-2 
      ${activeInsight === 0 ? "border-[#67748e]" : "border-transparent"}`}
          >
            Campaign Insight
          </div>
          {/* <div
            onClick={() => setActiveInsight(1)}
            className={`cursor-pointer h-[43px] flex justify-center items-center py-3 px-5 
      text-[#67748e] text-lg whitespace-nowrap font-bold duration-300 
      transition ease-in-out border-b-2 
      ${activeInsight === 1 ? "border-[#67748e]" : "border-transparent"}`}
          >
            Participated Active User
          </div> */}
        </div>

        <div className="flex mb-4 flex-row justify-between items-center  border-b ">
          <div className="flex justify-self-end ml-auto justify-end items-center gap-2 w-fit">
            {filteredData?.length && activeInsight !== 1 ? (
              <div>
                <DownloadCSV
                  fileName={"customer-report-with-status"}
                  columns={insightColumns}
                  data={filteredData.map((item) => ({
                    ...item,
                    claimed: item.transferId ? "claimed" : "not claimed",
                    activationTimestamp: dayjs(item.activationTimestamp)
                      .tz("America/Los_Angeles")
                      .isValid()
                      ? dayjs(item.activationTimestamp)
                          .tz("America/Los_Angeles")
                          .format("YYYY-MM-DD:HH:mm:ss")
                      : "",
                  }))}
                  fieldsToInclude={[
                    "clientId",
                    "studentName",
                    "claimed",
                    "savingAccountId",
                    "cashbackAmount",
                    "transferId",
                    "activationTimestamp",
                  ]}
                />
              </div>
            ) : null}
          </div>
        </div>
        {loading ? (
          <div className="flex items-center justify-center w-full h-[400px]">
            <CircularProgress style={{ color: "#2cccd3" }} />
          </div>
        ) : (
          <div
            className="data-grid-container"
            style={{ height: 400, width: "100%" }}
          >
            <DataGrid
              rows={activeInsight !== 1 ? filteredData : participatedUsers}
              columns={
                activeInsight !== 1 ? insightColumns : participatedUserColumns
              }
              pageSize={pageSize}
              pageSizeOptions={[5, 10, 15, 20]}
              getRowId={(row, idx) => {
                return row?.id || row?.clientId || idx;
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewCampaignInsight;
