import React, { useEffect, useState } from "react";
import { Loader } from "../../components";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import { getStudentReportsAPI } from "../../api/api";
import SearchBox from "../../shared/SearchBoxes/SearchBox";
import DownloadCSV from "../../shared/downloadCSV/DownloadCSV";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import classNames from "classnames";
import { is } from "date-fns/locale";
import { isValidDate } from "../../utils/func";

const CustomerReportsPage = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  //   const [searchQuery, setSearchQuery] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const columns = [
    { field: "id", headerName: "ESTU ID", width: 100, download: true },
    { field: "name", headerName: "Name", width: 200, download: true },
    { field: "email", headerName: "Email", width: 250, download: true },
    {
      field: "collegeName",
      headerName: "School Name",
      width: 200,
      download: true,
    },
    { field: "mobileNo", headerName: "Phone", width: 150, download: true },
    {
      field: "createdAt",
      headerName: "Date Time (EST)",
      width: 200,
      download: true,
      completeDate: true,
      renderCell: (params) => {
        const formattedDateTime = new Date(params.row.createdAt)
          .toISOString()
          .replace("T", " ")
          .slice(0, 19)
          .replace(/-/g, "/");
        return (
          <p className="" title="yyyy-mm-dd:hh:mm:ss time format EST">
            {dayjs(params?.row?.createdAt)
              ?.tz("America/New_York")
              .format("YYYY-MM-DD:HH:mm:ss")}
          </p>
        );
      },
    },
    {
      field: "savingAccountNumber",
      headerName: "Checking Account No.",
      csvHeaderName: "checkingAccountNumber",
      width: 250,
      download: true,
    },
    { field: "clientId", headerName: "Client Id", width: 150, download: true },
  ];

  const getAPI = async () => {
    //API call
    setLoading(true);
    try {
      const response = await getStudentReportsAPI();
      // console.log(response?.data);
      const data = response?.data || [];
      setData(data);
      setFilteredData(data);
    } catch (error) {
      console.log(error);
      //   toast.error("something went wrong with fetching data");
    } finally {
      setLoading(false);
    }
  };
  const handleBlur = () => {
    if (!searchQuery) {
      setIsSearchActive(false);
    }
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setFilteredData(data);
    setIsSearchActive(false);
  };

  useEffect(() => {
    getAPI();
  }, []);

  // const handleSearch = (query) => {
  //   if (query) {
  //     const filtered = data?.filter((item) =>
  //       Object?.values(item)?.some((value) =>
  //         String(value)?.toLowerCase()?.includes(query?.toLowerCase())
  //       )
  //     );
  //     setFilteredData(filtered);
  //   } else {
  //     setFilteredData(data);
  //   }
  // };

  const handleSearch = () => {
    let filtered = data;

    // Apply search query filter
    if (searchQuery) {
      filtered = filtered.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }

    if (isValidDate(startDate)) {
      // Convert startDate and endDate to UTC timestamps
      const startTimestamp = startDate
        ? new Date(dayjs(startDate).startOf("day").toISOString()).getTime()
        : null;

      // Apply filtering based on start date
      if (startTimestamp) {
        filtered = filtered.filter((item) => {
          const createdAt = new Date(item.createdAt).getTime();
          return createdAt >= startTimestamp;
        });
      }
    }
    if (isValidDate(endDate)) {
      const endTimestamp = endDate
        ? new Date(dayjs(endDate).endOf("day").toISOString()).getTime()
        : null;

      // Apply filtering based on end date
      if (endTimestamp) {
        filtered = filtered.filter((item) => {
          const createdAt = new Date(item.createdAt).getTime();
          return createdAt <= endTimestamp;
        });
      }
    }

    setFilteredData(filtered);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    handleSearch(); // Trigger search on start date change
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    handleSearch(); // Trigger search on end date change
  };
  useEffect(() => {
    // console.log("hello");
    handleSearch();
  }, [startDate, endDate, searchQuery, data]);

  return (
    <Box className="px-4 py-4 flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <Typography
          variant="h4"
          className="text-[#344767] font-bold whitespace-nowrap"
        >
          Customer Reports
        </Typography>
        <div className="flex justify-center items-center gap-4 p-2">
          <div
            className={classNames(`flex gap-2`, {
              hidden: isSearchActive,
            })}
          >
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => {
                // console.log(newValue);
                setStartDate(newValue);
              }}
              format="DD/MM/YYYY"
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              format="DD/MM/YYYY"
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          <div className="relative">
            <SearchBox
              onSearch={(query) => setSearchQuery(query)}
              placeholder="Search something..."
              data={data}
              active={isSearchActive}
              setActive={setIsSearchActive}
            />
          </div>
          <div>
            <DownloadCSV
              fileName={"estu-customer-report"}
              columns={columns}
              data={filteredData}
              fieldsToInclude={[
                "id",
                "name",
                "email",
                "collegeName",
                "mobileNo",
                "createdAt",
                "savingAccountNumber",
                "clientId",
              ]}
            />
          </div>
        </div>
      </div>
      <hr />

      <Box className="bg-white rounded-lg shadow-md p-4">
        {loading ? (
          <Box className="flex justify-center align-center">
            <Loader />
          </Box>
        ) : (
          <>
            {filteredData?.length > 0 ? (
              <DataGrid
                rows={filteredData}
                columns={columns}
                pagination
                pageSize={10}
                autoHeight
                className="bg-white"
              />
            ) : (
              <Box className="flex justify-center items-center min-h-64">
                <Typography variant="h6" color="textSecondary">
                  No data available
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default CustomerReportsPage;
