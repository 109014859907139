import React from "react";
import CottageIcon from "@mui/icons-material/Cottage";
import dashboardIcon from "../../assets/svgs/dashboard.svg";
import StudentIcon from "../../assets/svgs/stuvercon.svg";
import ProfileIcon from "../../assets/svgs/profileIcon.svg";
import helpIcon from "../../assets/svgs/helpIcon.svg";
import { useStateContext } from "../../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";
import ContactUsMenuChip from "../../shared/ContactUsMenuChip/ContactUsMenuChip";
import {
  WaitListIcon,
  AdsIcon,
  Logo,
  KYCERRORSICONS,
  LogoutIcon,
} from "./../../assets/svgs/waitlist";
import {
  ListAlt as ListAltIcon,
  AdUnits as AdUnitsIcon,
  Group as GroupIcon,
  School as SchoolIcon,
  Public as PublicIcon,
  LocalOffer as LocalOfferIcon,
  LocalMall as LocalMallIcon,
  ErrorOutline as ErrorOutlineIcon,
  VerifiedUser as VerifiedUserIcon,
  AccountBalance as AccountBalanceIcon,
  PersonOff as PersonOffIcon,
  Email as EmailIcon,
  ReportOffOutlined,
  ReportGmailerrorredTwoTone,
  ScaleOutlined,
  ReportOffRounded,
} from "@mui/icons-material";
import { useAuth } from "../../contexts/AuthContext";

const Sidebar = () => {
  const { currentColor, activeMenu, setActiveMenu, screenSize } =
    useStateContext();

  const { canAccess, userDepartment } = useAuth();

  const navigate = useNavigate();

  const department = userDepartment();

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.clear();
    window.location.reload();
  };

  const menuItems = [
    {
      url: "/dashboard",
      name: "Dashboard",
      icon: <CottageIcon />,
      department: ["ADMIN"],
    },
    {
      url: "/customer-reports",
      name: "Customer Reports",
      icon: <ReportGmailerrorredTwoTone />,
      department: ["ADMIN"],
    },

    {
      icon: <AdUnitsIcon />,
      url: "/ads",
      name: "Ads Manager",
      department: ["ADMIN"],
    },
    {
      url: "/estu-ads",
      icon: <GroupIcon />,
      name: "Partners",
      department: ["ADMIN"],
    },
    {
      icon: <LocalOfferIcon />,
      url: "/coupons-management",
      name: "Coupons & Events",
      department: ["ADMIN"],
    },
    {
      icon: <LocalMallIcon />,
      url: "/deals",
      name: "Deals",
      department: ["ADMIN"],
    },
    {
      icon: <ReportOffRounded />,
      url: "/referrals",
      name: "Referrals",
      department: ["ADMIN"],
    },

    {
      icon: <ErrorOutlineIcon />,
      url: "/kycerrors",
      name: "KYC Errors",
      department: ["ADMIN"],
    },
    {
      icon: <ScaleOutlined />,
      url: "/campaigns-management",
      name: "Campaigns",
      department: ["ADMIN"],
    },
    {
      icon: <PersonOffIcon />,
      url: "/international-student",
      name: "International Student",
      department: ["ADMIN"],
    },
    {
      icon: <VerifiedUserIcon />,
      url: "/allowedUsers",
      name: "Allowed Users",
      department: ["ADMIN"],
    },
    {
      icon: <AccountBalanceIcon />,
      url: "/mbanq",
      name: "MBanq",
      department: ["ADMIN"],
    },

    {
      // icon: <AdsIcon />,
      icon: <EmailIcon />,
      url: "/comms",
      name: "COMMS",
      department: ["ADMIN", "MARKETING"],
    },
    // {
    //   url: "/waitlist",
    //   name: "Wait List",
    //   icon: <ListAltIcon />,
    //   department: ["ADMIN"],
    // },
    {
      icon: <SchoolIcon />,
      url: "/instituteList",
      name: "Institute List",
      department: ["ADMIN"],
    },
    {
      icon: <PublicIcon />,
      url: "/countryList",
      name: "Country List",
      department: ["ADMIN"],
    },
  ];

  return (
    <div className="py-6 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto hover:no-scrollbar pb-10">
      {activeMenu && (
        <>
          <div className=" flex justify-center  items-center">
            <Logo />
          </div>
          <div className="sidebar-line"></div>

          <div className="px-2 pb-4">
            {/* NOTE:- using waitlist route for waitlist users this commented code was their, i commented it to use this route */}
            {/* {process.env.REACT_APP_BASEURL ==
            "https://estu-admin-prod.estulife.com/" ? (
              <MenuChip
                navigate={navigate}
                url={"/waitlist"}
                name={"Wait List"}
                icon={<AdsIcon />}
              />
            ) : null} */}
            {menuItems

              .filter((menu) => menu.department.includes(department))
              .map((menu, idx) => (
                <MenuChip
                  key={idx}
                  navigate={navigate}
                  url={menu?.url}
                  name={menu?.name}
                  icon={menu?.icon}
                />
              ))}
            <button
              onClick={() => logout()}
              className={`items-center py-[11px] pl-[16px] mt-2 hover:shadow-md cursor-pointer flex rounded-[8px] group hover:bg-red-400 transition-all duration-300 hover:text-white w-full`}
            >
              <div
                className={`text-black rounded-[8px] shadow p-2  group-hover:bg-white group-hover:!text-white`}
              >
                <LogoutIcon />
              </div>
              <div className="pl-[14px] text-red-400 group-hover:text-white">
                Logout
              </div>
            </button>
          </div>
          {/* <ContactUsMenuChip /> */}
        </>
      )}
    </div>
  );
};

export default Sidebar;

export const MenuChip = ({ navigate, url, name, icon }) => (
  <div
    onClick={() => navigate(url)}
    className={` ${
      window.location.pathname === url ? "bg-white shadow-lg " : ""
    } items-center py-[11px] pl-[16px] cursor-pointer flex rounded-[8px] whitespace-nowrap w-full`}
  >
    {icon && (
      <div
        className={`${
          window.location.pathname === url
            ? "bg-[#2CCCD3] text-white"
            : "text-black"
        }  rounded-[8px] shadow p-[10px] `}
      >
        {icon ? icon : null}
      </div>
    )}
    <div className="pl-[14px] pr-2 w-full">{name}</div>
  </div>
);
