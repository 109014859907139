import React, { useState, useEffect, useRef } from "react";
import InputComponent from "../atoms/InputComponent";
import { ImageUpload } from "../../pages/adsmaster/discountCoupons/imageUpload";
import LocationInput from "./LocationField";
import { getSectors } from "../../api/api";
import {} from "react";
import UsersIcon from "../../assets/svgs/user_admaster.svg";
import MultiSelectIndustry from "./MultiSelectIndustry";
import TextComponent from "../atoms/TextComponent";

const GenericFormField = ({
  field,
  formData,
  setFormData,
  handleInputChange,
  hide = false,
}) => {
  const fileInputRef = useRef();

  const [options, setOptions] = useState([]);
  console.log(field?.name, formData, "bharat");

  const handleNumberInputChange = (e) => {
    const { name, value, maxLength } = e.target;
    const newValue = value.slice(0, maxLength);

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };
  useEffect(() => {
    const fetchSectorsOptions = async () => {
      try {
        const sectors = await getSectors();
        const updatedSectors = sectors.map((item) => {
          // console.log(item);
          return { value: item.id, name: item.name, label: item.name };
        });
        console.log("update", updatedSectors);
        setOptions(updatedSectors);
      } catch (err) {
        console.log("err", err);
      }
    };

    // console.log(options);
    options?.length === 0 &&
      field?.name === "industry" &&
      fetchSectorsOptions();
  }, []);
  if (hide) return null;
  return (
    <div className="flex flex-col text-left gap-2">
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={(e) => {
          ImageUpload(
            e,
            setFormData,
            field.type == "profilePhoto" ? "profilePhoto" : "poster"
          );
        }}
      />
      <label className={style.formTextPlaceholder}>{field.placeholder}</label>
      {field.type === "radioType" ? (
        <div className="flex flex-col text-left gap-2">
          <div className={`flex gap-3 flex-wrap`}>
            {field?.options &&
              field?.options?.map((option, idx) => (
                <div
                  className="flex gap-4 items-center justify-between cursor-pointer"
                  title={option?.title}
                  key={idx}
                >
                  <input
                    type="radio"
                    id={`${field.name}-${idx}`}
                    name={field.name}
                    value={option?.value}
                    checked={formData[field.name] === option?.value}
                    onChange={(e) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        [field.name]: e.target.value,
                      }));
                    }}
                  />
                  <label
                    title={option?.title}
                    htmlFor={`${field.name}-${idx}`}
                    className="cursor-pointer"
                  >
                    {option?.label ? option?.label : option?.value}
                  </label>
                </div>
              ))}
          </div>
        </div>
      ) : field.type === "when" ? (
        <InputComponent
          type={"date"}
          name={field.name}
          placeholder={field.placeholder}
          value={formData[field.name] || ""}
          onChange={handleInputChange}
        />
      ) : field.type === "time" ? (
        <InputComponent
          type="time"
          name={field.name}
          placeholder={field.placeholder}
          value={formData[field.name] || ""}
          onChange={handleInputChange}
        />
      ) : field.type === "email" ? (
        <InputComponent
          type="email"
          name={field.name}
          placeholder={field.placeholder}
          value={formData[field.name] || ""}
          onChange={handleInputChange}
          {...field}
        />
      ) : field.type === "radio" ? (
        <div className="flex flex-col text-left gap-2">
          <div className={`flex gap-2 `}>
            {field.options.map((option) => (
              <div className={`flex gap-2`} key={option}>
                <div
                  onClick={() => {
                    setFormData((prevData) => ({
                      ...prevData,
                      [field.name]: option,
                    }));
                  }}
                  className={`w-6 h-6 rounded-full border border-solid border-lightgray transition duration-300 ease-in-out bg-white ${
                    formData?.[field.name] === option
                      ? "ring-[1px] ring-white ring-offset-[1px] ring-offset-[#2cccd3] bg-[#2cccd3]"
                      : ""
                  }`}
                ></div>

                <label
                  htmlFor={`${field.name}-${option}`}
                  onClick={() => {
                    setFormData((prevData) => ({
                      ...prevData,
                      [field.name]: option,
                    }));
                  }}
                >
                  {option}
                </label>
              </div>
            ))}
          </div>
        </div>
      ) : field.type === "image" ? (
        <div
          onClick={(_) => fileInputRef.current.click()}
          className={`flex flex-col gap-2 items-center`}
        >
          <div className="flex flex-col gap-2 p-2 w-300 h-100 justify-center items-center self-stretch rounded bg-[#dbeff5]">
            {formData.poster ? (
              <img
                className="w-[300px] h-[100px]  rounded-10"
                src={`${process.env.REACT_APP_IMAGE_BASEURL}${formData.poster}`}
                alt="Event Poster"
              />
            ) : (
              <>
                <div>
                  <svg
                    className="w-10 h-10 stroke-current text-gray-600"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23 19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8C1 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H7L9 3H15L17 6H21C21.5304 6 22.0391 6.21071 22.4142 6.58579C22.7893 6.96086 23 7.46957 23 8V19Z"
                      stroke="#687A7D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17Z"
                      stroke="#687A7D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="text-gray-600 font-Montserrat text-14 font-medium">
                  <p>Event poster</p>
                </div>
              </>
            )}
          </div>

          <div className="overflow-hidden text-center text-ellipsis font-montserrat text-primary-300 text-xs font-medium">
            Upload from Computer
          </div>
        </div>
      ) : field.type === "profilePhoto" ? (
        <div
          onClick={(_) => fileInputRef.current.click()}
          className={`flex flex-col gap-2 items-center`}
        >
          <div className="flex relative flex-col group gap-2 w-28 h-28 justify-center items-center self-stretch rounded-full bg-[#dbeff5]">
            {
              <>
                <img
                  className="rounded-full group-hover:opacity-50 group-hover:absolute w-28 h-28 object-cover cursor-pointer"
                  src={
                    formData.profilePhoto
                      ? `${process.env.REACT_APP_IMAGE_BASEURL}${formData.profilePhoto}`
                      : UsersIcon
                  }
                  alt="Profile Photo"
                  title="profile photo"
                />
                <div className="group-hover:flex flex-col justify-center items-center top-0 left-0 right-0 bottom-0 w-full h-full hidden duration-200 delay-200 transition-opacity">
                  <svg
                    className="w-10 h-10 stroke-current text-gray-600"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23 19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8C1 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H7L9 3H15L17 6H21C21.5304 6 22.0391 6.21071 22.4142 6.58579C22.7893 6.96086 23 7.46957 23 8V19Z"
                      stroke="#687A7D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17Z"
                      stroke="#687A7D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            }
          </div>

          {/* <div className="overflow-hidden text-left text-ellipsis font-montserrat text-primary-300 text-xs font-medium">
            {formData.profilePhoto ? "Change Image" : "Upload Image"}
          </div> */}
        </div>
      ) : field.type === "multiselect" ? (
        <MultiSelectIndustry formData={formData} setFormData={setFormData} />
      ) : field.type === "select" ? (
        <select
          className="p-3 border border-black outline-none rounded-md w-100 min-h-[50px]"
          name={field.name}
          value={formData[field.name]}
          onChange={handleInputChange}
          required
        >
          <option value="" disabled>
            Select an industry
          </option>
          {options?.map(({ value, name }) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </select>
      ) : field.type === "calendar" ? (
        <>
          <div className={`flex gap-2 mt-2`}>
            <label className={`h6 w-[100px]`}>Valid From</label>
            <input
              className="p-3 border border-black rounded-md outline-none"
              type="date"
              name={`${field.name}From`}
              value={formData[`${field.name}From`]}
              onChange={handleInputChange}
            />
          </div>
          <div className={`flex gap-2`}>
            <label className={`h6 w-[100px]`}>Valid To</label>
            <input
              type="date"
              className="p-3 border border-black rounded-md outline-none"
              name={`${field.name}To`}
              value={formData[`${field.name}To`]}
              onChange={handleInputChange}
            />
          </div>
        </>
      ) : field.type === "textarea" ? (
        <TextComponent
          type="textarea"
          name={field.name}
          placeholder={field.placeholder}
          value={formData[field.name] || ""}
          onChange={handleInputChange}
          rows={4}
        />
      ) : field.type === "deliveryAddress" ||
        field.type === "contractAddress" ? (
        <AddressInput
          field={field}
          formData={formData}
          setFormData={setFormData}
        />
      ) : field.name === "where" ? (
        <LocationInput field={field} setFormData={setFormData} />
      ) : (
        <InputComponent
          type="text"
          name={field.name}
          placeholder={field.placeholder}
          value={formData[field.name] || ""}
          onChange={handleInputChange}
        />
      )}
    </div>
  );
};

export default GenericFormField;

const AddressInput = ({ field, formData, setFormData }) => {
  const getValue = (fieldName) => {
    const keys = fieldName.split(".");
    return keys.reduce(
      (obj, key) => (obj && obj[key] ? obj[key] : ""),
      formData
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split(".");
    const lastKey = keys.pop();

    setFormData((prevData) => {
      const nestedObj = keys.reduce(
        (obj, key) => (obj[key] = obj[key] || {}),
        prevData
      );
      nestedObj[lastKey] = value;
      return { ...prevData };
    });
  };
  return (
    <div className="flex flex-col gap-2">
      {/* <label htmlFor={field.name}>{field.placeholder}</label> */}
      {/* {console.log(field.name.split(".")[1], "bharattttt")} */}
      <InputComponent
        type="text"
        name={field.name}
        placeholder={`Enter ${field.placeholder}`}
        value={getValue(field.name) || ""}
        onChange={(e) => {
          const { name, value } = e.target;
          const [type, key] = name.split(".");
          // console.log(type, key, "bharatttttq");
          setFormData((prevData) => ({
            ...prevData,
            [type]: {
              ...prevData[type],
              [key]: value,
            },
          }));
        }}
      />
    </div>
  );
};

const style = {
  imageContainerStyle: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    width: "300px",
    height: "100px",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    borderRadius: "10px",
    background: "#dbeff5",
  },
};
// <select
//   className="p-3 border border-black outline-none rounded-md w-100 text-red-300"
//   name={field.name}
//   multiple // Enable multi-select
//   // value={formData[field.name] || []} // Ensure selected options are displayed correctly
//   // onChange={handleMultiSelectChange} // Handle change event
// >
//   {options.map((option) => (
//     <option key={option.value} value={option.value}>
//       {option.name}
//     </option>
//   ))}
// </select>
